<!-- eslint-disable -->
<template>
  <v-row justify="center">

    <v-snackbar
      v-model="snack.snackbar"
      :vertical="snack.vertical"
      :color="snack.color"
      :right="true"
      :top="true"
    >
      <div class="c-snackbar__active">
        <svg class="mr-4" width="18.021" height="17.778" viewBox="0 0 18.021 17.778">
          <g id="check-circle" transform="translate(-1.039 -1.074)">
            <path id="Trazado_1" data-name="Trazado 1" d="M18,9.262V10a8,8,0,1,1-4.744-7.312" transform="translate(0 0)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="Trazado_2" data-name="Trazado 2" d="M19.13,4l-7.793,7.8L9,9.463" transform="translate(-1.131 -0.443)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
        </svg>
        {{ snack.txt }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          color="#007AFF"
          @click="snack.snackbar = false"
        >
          {{$t('ensayos.cerrar')}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="padding-right: 25px !important;
            padding-bottom: 0px !important; padding-left: 29px !important;">
          <p class="bold mb-0 txt-enasyo">{{ $t('ensayos.txt_title') }}</p>
          <v-spacer></v-spacer>
           <v-btn icon  @click="dialog = false">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mr-2 space-between">
              <v-col class="pl-5" cols="4" >
                <v-text-field
                :label="$t('ensayos.test')"
                v-model="form_lab.name"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="4">
                <v-select
                  :items="langCat(data_ensayo.catalogoTipoEnsayos)"
                  item-value="id"
                  item-text="nombre3"
                  :label="$t('ensayos.category')"
                  v-model="form_lab.MT"
                ></v-select>
              </v-col>
              <v-col class="d-flex direction-colum justify-center
              align-items-center" cols="2">
                <v-btn
                  color="#344C98"
                  class="ma-2 white--text"
                 @click="savenewLab()"
                >
                  {{ $t('ensayos.add') }}
                  <v-icon
                    right
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ml-2 mr-3 b-bottom space-between">
              <v-col class="text-left" cols="4">
                <p class="bold mb-0">{{ $t('ensayos.test') }}</p>
              </v-col>
              <v-col class="bold text-left" cols="4">{{ $t('ensayos.tezt_category') }}</v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <div class="content-ensayo">
              <v-row class="ml-2 mr-3 b-bottom space-between"
                v-for="item in data_ensayo.tipo_ensayos"
                :key="'Lab_'+item.id_tipo_de_ensayo">
                <v-col class="" cols="4">
                  <v-text-field
                  solo
                  dense
                  :disabled="item.default==1"
                  v-model="item.descripcion_tipo_de_ensayo"
                   class="field-parametro"
                  ></v-text-field>
                </v-col>
                <v-col class="" cols="4">
                  <v-select
                    solo
                    dense
                    :items="langCat(data_ensayo.catalogoTipoEnsayos)"
                    item-value="id"
                    item-text="nombre3"
                    :disabled="item.default==1"
                    v-model="item.inlaca_tipoensayo_id"
                  ></v-select>
                </v-col>
                <v-col  cols="3">
                    <a class="mr-4" @click="editLab(item)"
                    v-if="item.default !== 1">
                      <img width="17" src="/img/editar.svg" >
                    </a>
                    <a  @click="dropLab(item)"><img width="17" src="/img/eliminar.svg"
                    v-if=" item.default!==1 "></a>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--<v-btn
            class="ma-2 c-btn c-btn--primary"
            color="indigo darken-3"
            outlined
            text
          >
           {{ $t('ensayos.guardar') }}
          </v-btn>-->
          <v-btn
            class="ml-5 ma-2 c-btn  c-btn--black"
            outlined
            text
            @click="dialog = false"
          >
            {{ $t('ensayos.cerrar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';

export default {
  data: () => ({
    dialog: false,
    catalogo: [],
    form_lab: {
      name: '',
      cliente_id: 1,
      default: 0,
      MT: 0,
    },
    listlab: [],
    loading5: false,
    snack: {
      snackbar: false,
      txt: null,
      color: '##F5F5F5',
      error: false,
      x: null,
      y: 'top',
    },
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  created() {

  },
  methods: {
    openmodal() {
      this.dialog = true;
    },
    langCat(items) {
      items.forEach((element) => {
        const name = element.nombre.replace(/\s+/g, '');
        const trad = `${i18n.t(`modal_tipo_ensayo.${name}`)}`;
        /* eslint-disable no-param-reassign */
        element.nombre3 = trad;
      });
      return items;
    },
    async savenewLab() {
      if (this.form_lab.name === '') {
        return;
      }
      this.loading5 = true;
      const nuevolab = await Service.apiToken('POST', 'new-tipoensayo', this.$ls.storage.token, this.form_lab);
      this.snack.snackbar = true;
      this.snack.color = '#F5F5F5';
      this.snack.txt = `${this.form_lab.name} ${i18n.t('ensayos.save_succes')}`;
      // push al objeto de laboratorios
      this.data_ensayo.tipo_ensayos = nuevolab.data;
      // vaciar formulario de lab
      // this.form_lab.direccion = '';
      this.form_lab.name = '';
      this.loading5 = false;
    },
    async editLab(item) {
      await Service.apiToken('POST', 'edit-ensayotipo', this.$ls.storage.token, item);
      this.snack.snackbar = true;
      this.snack.color = '#F5F5F5';
      this.snack.txt = i18n.t('ensayos.update_succes');
    },
    async dropLab(item) {
      await Service.apiToken('POST', 'delete-ensayotipo', this.$ls.storage.token, item);
      let key = null;
      this.data_ensayo.tipo_ensayos.map((lab, index) => {
        if (item.id_tipo_de_ensayo === lab.id_tipo_de_ensayo) {
          key = index;
        }
        return true;
      });
      this.data_ensayo.tipo_ensayos.splice(key, 1);
    },
  },
};
</script>
